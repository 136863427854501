@use 'sass:map';

.section-card {
  background: map.get($theme, alert-bg);
  border-radius: 12px;
  padding: 32px;

  h4 {
    color: map.get($theme, highlight-800) !important;
    @media screen and (max-width: $small) {
      max-width: 272px;
    }
    margin-bottom: 0;
  }

  button {
    max-width: 209px;
  }
}