
.button-xs {
  width: 32px;
  height: 32px;
  padding: 10px;
  border-radius: 16px;
  .btn-icon {
    margin: 0;
    height: 12px;
    width: auto; 
    // margin-right: 12px;
  }
  
  button {
    display: inherit;
  }
}

