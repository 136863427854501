@use 'sass:map';

.faq-entry {
  .card-header {
    padding-left: 0;
    padding-right: 0;
    .accordion-button {
      h6 {
        color: map.get($theme, primary);
        margin-bottom: 0;
      }
      img {
        margin-left: 32px;
      }
    }
  }

  .card-header:hover {
    background: map.get($theme, default-100);
  }

  .card-body {
    padding-left: 0;
  }

  .faq-question{
    h6 {
      white-space: unset;
    }
  }
  
}