@use 'sass:map';

.top-navbar {
  max-height: 32px;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: inset 0px -1px 0px #E9EBED;
}

.top-navbar {
  .navbar-nav {
    .nav-link {
      @include s1;
      color: map.get($theme, default-500);
    }
    .nav-link.active {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 20px;
      font-family: "SourceSansProRegular";
    }
  }

  // .navbar-light {
  //   box-shadow: 0px 4px 12px rgba(104, 115, 125, 0.1);
  //   .navbar-nav {
  //     .nav-link {
  //       font-size: 13px !important;
  //     }
  //   }
  // }

}


