@use 'sass:map';

.home-highlight-section {
  background: map.get($theme, home-highlight-bg);
  padding-top: 66.5px;
  padding-bottom: 64px;
  .home-highlights {
    padding-bottom: 64px;
    gap: 30px;
    div {
      background: #FFFFFF;
      box-shadow: 0px 8px 16px 8px rgba(20, 142, 142, 0.05);
      border-radius: 12px;
      padding: 24px;
      // max-width: 378px;
      max-width: 392px; // To cover the entire width
      // max-height: 360px;

      h4 {
        margin-top: 24px;
        // color: map.get($theme, primary-800);
        // margin-bottom: 24px;
        // font-style: normal;
        // font-weight: normal;
        // font-size: 32px;
        // line-height: 40px;
      }
      p {
        max-width: 352px;
        height: 84px;
        margin-bottom: 32px;
      }
    }
  }
  p {
    // max-width: 1176px;
    color: map.get($theme, default-700)
  }
  
}