

@use 'sass:map';
// /* import only the necessary Bootstrap files */
// @import "../../../node_modules/bootstrap/scss/functions";
// @import "../../../node_modules/bootstrap/scss/variables";

/* -------begin customization-------- */
$breadcrumb-bg: map.get($theme, breadcrumb-bg);
$breadcrumb-divider-color: map.get($theme, breadcrumb-item);
$breadcrumb-active-color: map.get($theme, breadcrumb-item);
$breadcrumb-font-size: 13px;

$container-max-widths: (
sm: 540px,
md: 720px,
lg: 960px,
xl: 1260px,
xxl: 1280px
);

// List group

// $list-group-color:                  null !default;
// $list-group-bg:                     $white !default;
// $list-group-border-color:           rgba($black, .125) !default;
// $list-group-border-width:           $border-width !default;
// $list-group-border-radius:          $border-radius !default;

// $list-group-item-padding-y:         .75rem !default;
$list-group-item-padding-x:             0 !default;

$list-group-hover-bg:                  $white !default;
// $list-group-active-color:           $component-active-color !default;
// $list-group-active-bg:              $component-active-bg !default;
// $list-group-active-border-color:    $list-group-active-bg !default;

// $list-group-disabled-color:         $gray-600 !default;
// $list-group-disabled-bg:            $list-group-bg !default;

// $list-group-action-color:           $gray-700 !default;
// $list-group-action-hover-color:     $list-group-action-color !default;

// $list-group-action-active-color:    $body-color !default;
// $list-group-action-active-bg:       $white !default;

$input-btn-focus-box-shadow:           none;
$input-btn-focus-width:                0;
$link-color:                           map.get($theme, primary);

$card-cap-bg:                          $white !default;  
$card-cap-padding-y:                   12px;
$card-border-color:                    #E9EBED;

// @include _assert-ascending($container-max-widths, "$container-max-widths");
// /* -------end customization-------- */

/* finally, import Bootstrap to set the changes! */
// @import "../../../node_modules/bootstrap/scss/bootstrap";
@import "bootstrap";