@use 'sass:map';


.related-discussions {

  .discussion {
    // border-bottom: 1px solid map-get($theme, default-200);
    padding-bottom: 24px;
    span.author {
      @include buttons-2;
      margin-right: 8px;
    }
  
    span.timestamp-info {
      @include buttons-3;
      color: map.get($theme, default-500);
    }
  
    p.p3 {
      color: map.get($theme, default-800);
    }
  
    div.comment-actions {
      @include buttons-2;
      color: map.get($theme, default-500);
    }
    
    button {
      padding-left: 0;
    }
  }
}