@use 'sass:map';

.activities-list-group {
  // max-width: 684px;
  .list-group-item {
    // border-top: 1px solid #C2C8CC;
    background: transparent;
    // box-shadow: unset;
    // box-shadow: inset 0px 1px 0px #C2C8CC;
    padding-right: 5px;
    .author-info  {
      .author {
        @include buttons-2;
        color: map.get($theme, default-900);
        margin-right: 4px;
      }
      .timestamp-info {
        @include buttons-3;
        color: map.get($theme, default-500)
      }
    }

    .thumbnail {
      margin-right: 16px;
    }
  }
  .list-group-item.active {
    // .active {
      background: transparent;
      border-color: unset;
      box-shadow: inset 0px 1px 0px #C2C8CC;
    // }
    
    .list-group-item-action {

    }
  }  
}

.no-activity-container {
  
  .p3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #000000;
    margin-bottom: 0;
  }

  .p4 {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* or 143% */
    text-align: center;
    /* Gray / 500 */
    color: #87929D;
    white-space: pre-line;
  }
}

.no-activity-wrapper {
  min-height: 400px;
}

.post-actions {
  @include buttons-2;
}