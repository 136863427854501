@use 'sass:map';

.paginator {
  height: 40px;
  border-top: 1px solid map-get($theme, default-200);
  border-bottom: 1px solid map-get($theme, default-200);

  button {
    height: 38px;
    width: 40px;
    background: #FFFFFF;
    /* Borders / Left */
    box-shadow: inset 1px 0px 0px #E9EBED;
    border: 0;
    border-color: map-get($theme, default-200);
  }

  button:hover {
    background: map.get($theme, default-100)
  }

  button:disabled {
    // color: map.get($theme, button-disabled-bg)
    img {
        filter: invert(98%) sepia(52%) saturate(422%) hue-rotate(172deg) brightness(84%) contrast(87%);
    }
  }

  p {
    @include buttons-3
  }
}
