@use 'sass:map';

$white: #FFFFFF;

$grey-palette: (
  50: #FFFFFF,
  100: #FAFAFA,
  200: #E9EBED,
  300: #D8DCDE,
  400: #C2C8CC,
  500: #87929D,
  600: #68737D,
  700: #49545C,
  800: #252D33,
  900: #03080C
);

$pink-palette: (
  100: rgba(239, 188, 245, 0.25),
  200: rgba(239, 188, 245, 0.50),
  300: #EFBCF5,
  400: #D26CE0,
  500: #B755C5,
  600: #9E3BAB,
  700: #7B2E85,
);

$blue-palette: (
  100: rgba(179, 208, 255, 0.25),
  200: rgba(179, 208, 255, 0.50),
  300: #B3D0FF,
  400: #67A1FE,
  500: #0158E2,
  600: #0145B2,
  700: #01317F,
  800: #002993,
);

$teal-palette: (
  100: #CFFBFC, 
  300: #2FD4DA,
  400: #23C2C7,
  500: #20B1B6,
  600: #1DA0A5,
  700: #18878B,
  800: #13696C,
);

$color-secondary: $teal-palette;

$theme: (
  primary: map.get($blue-palette, 600),
  primary-300: map.get($blue-palette, 300),
  primary-500: map.get($blue-palette, 500),
  primary-600: map.get($blue-palette, 600),
  primary-400: map.get($blue-palette, 400),
  primary-800: map.get($blue-palette, 800),
  typescale: map.get($grey-palette, 800),
  typescale-light: map.get($grey-palette, 600),
  bg-light: map.get($grey-palette, 100),
  button: map.get($blue-palette, 500),
  button-primary-text: map.get($grey-palette, 50),
  button-outline-text: map.get($blue-palette, 500),
  button-disabled-text: map.get($grey-palette, 300),
  button-primary-bg: map.get($blue-palette, 500),
  button-primary-bg-hover: map.get($blue-palette, 600),
  button-primary-bg-focus: map.get($blue-palette, 700),
  button-outline-bg-hover: map.get($blue-palette, 100),
  button-outline-bg-focus: map.get($blue-palette, 200),
  button-outline-border-color: map.get($blue-palette, 300),
  button-disabled-bg: map.get($grey-palette, 400),
  background: map.get($grey-palette, 50),
  site-name-color: map.get($grey-palette, 700),
  breadcrumb-bg: map.get($teal-palette, 100),
  breadcrumb-item: map.get($teal-palette, 700),
  page-header-bg: map.get($teal-palette, 100),
  page-header-color: map.get($teal-palette, 700),
  navbar-tabs-link-color: map.get($grey-palette, 600),
  navbar-tabs-link-active-color: map.get($teal-palette, 600),
  navbar-tabs-item-border-color: map.get($teal-palette, 500),
  list-group-index-color: map.get($teal-palette, 500),
  alert-file-bg: map.get($blue-palette, 200),
  alert-file-title-color: map.get($blue-palette, 600),
  alert-file-color: map.get($grey-palette, 500),
  input-border-color: map.get($grey-palette, 300),
  select-active-color: map.get($blue-palette, 500),
  default-100: map.get($grey-palette, 100),
  default-200: map.get($grey-palette, 200),
  default-300: map.get($grey-palette, 300),
  default-400: map.get($grey-palette, 400),
  default-500: map.get($grey-palette, 500),
  default-700: map.get($grey-palette, 700),
  default-800: map.get($grey-palette, 800),
  default-900: map.get($grey-palette, 900),
  alert-bg: map.get($teal-palette, 100), 
  pageheader-subheading-color: map.get($teal-palette, 700),
  comments-icon-text-color: map.get($grey-palette, 900),
  highlight-bg: map.get($teal-palette, 100),
  highlight-600: map.get($teal-palette, 600),
  highlight-700: map.get($teal-palette, 700),
  highlight-800: map.get($teal-palette, 800),
  home-highlight-bg: #F1FEFE
);