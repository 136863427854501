.add-link-modal {
    overflow-y: auto;
}

.modal-backdrop.show {
    opacity: 0.6 !important;
}

.test-link {
    font-weight: 700;
    text-decoration: underline;
    font-size: 14px;
    font-family: 'SourceSansProRegular', sans-serif;
}

.text-grey {
    color: grey;
}

.hover-none {
    pointer-events: none;
}

.add-link-modal {
    .modal-dialog {
        max-width: 450px;
    }
}
