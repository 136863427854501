@use 'sass:map';


.navbar-tabs {


  background: $white;
  z-index: 999;
  @include spacing-horizontal;
  box-shadow: 0px 8px 12px 4px rgba(104, 115, 125, 0.05), inset 0px -1px 0px #E9EBED;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 40px;
  .navbar-class{
    justify-content: start;
  }
  .nav {
    overflow-x: auto;
    overflow-y:hidden;
    flex-wrap: nowrap;

    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    .nav-item  {
      white-space: nowrap;
      a {
        @include h6;
        @include sans-serif-regular;
        color: map.get($theme, navbar-tabs-link-color);
        padding: 16px 0px 16px 0;
        margin-right: 32px;
        font-weight: 400;
      }

      a.active {
        border-bottom: 4px solid map.get($theme, navbar-tabs-item-border-color);
        color: map.get($theme, navbar-tabs-link-active-color);
      }
      
    }
  }
  .nav::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}
}

.navbar-tabs-getting-started, .profile-navbar-tabs {
  top: 88px;
  @media screen and (max-width: 1024px){
    top: 60px
  }

  @media screen and (min-width: $small){
    top: 114px;
  }
  @media screen and (min-width: $medium){
    top: 132px;
  }
}

.breadcrumb-div {
  @media screen and (min-width: $small){
    top: 70px;
  }
  @media screen and (min-width: $medium){
    top: 88px;
  }
}

.profile-breadcrumbs, .getting-started-breadcrumbs {
  @media screen and (min-width: $small){
    top: 70px !important;
  }
  @media screen and (min-width: $medium){
    top: 88px !important;
  }
}