@font-face {
  font-family: "PoppinsMedium";
  src: local("PoppinsMedium"),
   url("./assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: bold;
 }

 @font-face {
  font-family: "PoppinsSemiBold";
  src: local("PoppinsSemiBold"),
   url("./assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: bold;
 }

 @font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsRegular"),
   url("./assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
 }

 @font-face {
  font-family: "SourceSansProRegular";
  src: local("SourceSansProRegular"),
   url("./assets/fonts/Source_Sans_Pro/SourceSansPro-Regular.ttf") format("truetype");
 }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
