
.button-default {
  display: inline-block;
  white-space: nowrap;

  .btn-icon {
    margin-left: 6px;
    width: 12px;
    height: auto; 
  }
}

.button-warning {
  background-color: rgba(243, 106, 104, 1);
  color: white;
  border: 0;
}

.button-warning:hover {
  background-color: rgb(231, 91, 88);
}