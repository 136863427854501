@use 'sass:map';

.module-card {
  background: #FFFFFF;
  /* Gray / 300 */

  border: 1px solid #D8DCDE;
  box-sizing: border-box;
  box-shadow: 0px 8px 12px 4px rgba(104, 115, 125, 0.05);
  border-radius: 8px;

  a p {
    color: map.get($theme, primary) !important;
  }
}