@use 'sass:map';

.list-group {
  // max-width: 684px;
  .list-group-item {
    background: transparent;
    .index {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px;
      position: static;
      width: 44px;
      height: 44px;
      left: 0px;
      top: 35px;
      background: rgba(82, 219, 224, 0.15);
      border-radius: 24px;
      /* Inside Auto Layout */
      flex: none;
      order: 0;
      flex-grow: 0;
      margin: 0px 24px 0px 0px;
      span {
        @include sans-serif-regular;
        font-size: 22px;
        line-height: 24px;
        color: map.get($theme, list-group-index-color);
        // margin: 10px 0px;
      }
    }
  }
  .list-group-item.active {
    // .active {
      background: transparent;
      border-color: unset;
      box-shadow: inset 0px -1px 0px #E8EDEF;
    // }
    
    .list-group-item-action {

    }
  }  
  .title-class {
    white-space: unset;
  }
}

