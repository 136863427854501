@use 'sass:map';

.form {
  .form-group {
    margin-bottom: 32px;
    label {
      @include p4;
      color: map.get($theme, default-800)
    }

    select {
      display: block;
      width: 100%;
      max-height: 40px;
      padding: 8px 20px 8px 8px;
      /* Gray/000 */
      background: #FFFFFF;
      /* Gray / 300 */

      border: 1px solid #D8DCDE;
      box-sizing: border-box;
      border-radius: 8px;
      @include p3;
    }

    select:focus-visible {
      border: 1px solid #D8DCDE;
      box-shadow: none;
    }
  }

  .form-group.required {
    label:after {
      content: " *";
      color: rgb(206, 17, 38);
    }
  }
}