@use 'sass:map';

.comments-icon {
  .btn-icon {
    margin: 0;
    height: 14px;
    width: 16px; 
  }
  span {
    @include buttons-2;
    color: map.get($theme, comments-icon-text-color);
  }
  
  button {
    display: inherit;
  }
}

