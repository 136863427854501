
@use 'sass:map';

.breadcrumb-div {
  background: map.get($theme, breadcrumb-bg);
  position: sticky;
}

.breadcrumb {
  @include spacing-horizontal;
  @include font-family-source-sans;
  font-size: 13px;
  line-height: 20px;
  padding-left: 0px;
}

.breadcrumb-item a {
  color: map.get($theme, breadcrumb-item);
  // height: 
}

ol.breadcrumb {
  margin-bottom: 0;
}