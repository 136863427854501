@use 'sass:map';

.file-alert-getting-started {
  padding: 24px;
  width: 100%;
  background-color: white;
  border-radius: 12px;
  margin-top: 20px;
  margin-bottom: 40px;
  // max-width: 684px;
  box-shadow: 0px 8px 16px 8px rgba(20, 142, 142, 0.05);
  border-radius: 12px;

  h5 {
    color: map.get($theme, primary-600)
  }

  div.col-lg-9 {
    max-width: 738px;
  }

  span {
    @include buttons-1;
    color: map.get($theme, alert-file-title-color);
    margin-bottom: 0;
    display: block;
  }

  small {
    color: map.get($theme, alert-file-color);
  }
}