@use 'sass:map';

.card-one {
  margin-bottom: 48px;
  div.left {
    margin-right: 30px;
  }

  div.right {
    max-width: 378px;
  }
}