@use 'sass:map';

button.modal-btn {
  p {
    color: #002993 !important;
    display: inline-block;
    padding-left: 10px;
  }
  

}

.button-default {
  display: inline-block;
  white-space: nowrap;

  .btn-icon {
    margin-left: 6px;
    width: 12px;
    height: auto; 
  }
}
