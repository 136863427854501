@use 'sass:map';

.hero-section {
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.hero-section-description {
  flex: 1;
  min-width: 300px;
  margin-right: 20px;

  h1 {
    max-width: 674px;
    margin-bottom: 20px;
  }

  p {
    max-width: 576px;
  }

  .actions {
    margin-top: 36px;
    max-width: 576px;
  }

  div {
    button {
      @media screen and (min-width: $medium) {
        margin-right: 16px;
      }
      
      @media screen and (max-width: $small) {
        margin-right: 0;
      }
    }
  }
}

.hero-image {
  width: 100%;
  max-width: 480px;
  height: auto;
  object-fit: cover;
}

.hero-image-container {
  flex: 0 0 auto;
}

@media screen and (max-width: 868px) {
  
  .hero-section-description .actions {
    flex-wrap:nowrap !important;
  }

  .hero-image-container {
    width: 100%;
    padding-top: 1rem;
  }

  .hero-image {
    max-width: none;
  }
}

@media (max-width: 1024px) {
  .hero-section-description .actions {
    flex-wrap: wrap;
    gap: 1rem;
  }
}