@use 'sass:map';


.trp-switch {
  padding-top: 8px;
  padding-bottom: 8px;
  .form-check {
    padding-left: 0;
    label {
      margin-bottom: 0;
      color: map.get($theme, default-800)
    }
    .form-check-input {
      position: relative;
      margin-left: 0;
    }
  }
  
}