@use 'sass:map';

.report-abuse-modal {
  .modal-dialog {
    max-width: 540px;
  }
  
  .modal-content {
    padding: 0 12px;
    border-radius: 10px;
  }
  
  .modal-header {
    border: 0;
    // padding-bottom: 0;
  }

  .modal-footer {
    border: 0;
  }

  .modal-body {
    padding: 0 12px;

    .form-check {
      label {
        @include h6-regular;
        padding-left: 10px;
      }
      margin-bottom: 12px;
    }
    .title-section {
      margin-bottom: 24px;
    }

    
  }
}