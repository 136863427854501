@use 'sass:map';

.community-warning {
  h6 {
    white-space: break-spaces;
    span {
      color: map.get($theme, primary-500);
      color: #0145B2 !important;
    }
  }

}