@use 'sass:map';
.video-block-container{
    margin-bottom: 40px;
    .video-thumbnail {
        width: 132px;
        height: 72px;
    };
    .video-details {
        padding-left: 28px;
        .video-name {
            color: #0145B2;
            font-weight: 600;
            font-size: 16px;
            width: 171px;
            -moz-box-orient: vertical;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            display: -webkit-box;
        }
        .video-duration {
            color: #87929D;
            font-weight: 400;
            font-size: 13px;
        }
    }
}