@use 'sass:map';

.home-news-section {
  width: 100%;
  padding-top: 56px;
  // padding-bottom: 64px;
  h4 {
    margin-bottom: 44px;
    // color: map.get($theme, primary-800)
  }
  .home-news {
  }

  .news-item {
    // max-width: 276px;
    .image-container {
      margin-bottom: 20px;
      overflow: hidden;
    }
    img {
      width: 100%;
      // min-height: 162px;
      // height: 162px;
      // margin-bottom: 24px;
    }
    span {
      display: block;
      // font-family: Gordita;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 12px;
      /* identical to box height, or 100% */
      // letter-spacing: 0.1em;
      // text-transform: uppercase;

      /* Gray / 500 */
      color: map.get($theme, default-500);
      margin: 8px 0px;
    }
    .news-title {
      @include p3;
      // max-width: 276px;
      // font-family: Gordita;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      /* or 156% */


      /* Dark blue */

      color: #03080C;
      /* Inside Auto Layout */
      margin: 8px 0px;
    }
    
    p {
      max-height: 100px;
      overflow: hidden;
      // font-family: Gordita;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
    }

    p.p4 {
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 4;
      -webkit-box-orient: vertical;
    }
    
    span {
      color: #03080C;
    }

    div.d-block {
      img {
        width: 70px;
        margin-left: 16px;
      }
    }
    .news-container-with-link {
      text-decoration: none;
    }
    .news-container-with-link:hover {
      .p3{
        color: #0158E2;
      }
    }
  }
  
}