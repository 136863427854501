@use 'sass:map';

.news-bg {
  background: #CFFBFC;
}
.subscribe-section {
  padding-top: 35px;
  padding-bottom: 35px;

  h4 {
    // color: map.get($theme, primary-800)
    font-size: 20px;
    color: map.get($theme, default-900);
    line-height: 28px;
  }
  .form-group {
    margin-bottom: 0px;
    width: 100%;
    input, div {
      @media screen and (min-width: $medium) {
        // min-width: 250px;
        width: 100%;
        clear: both;
      }
    }

    input {
      box-shadow: 0px 8px 16px 8px rgba(20, 142, 142, 0.05);
      border-radius: 4px;
      border: 0;
    }
  }
}

.custom-header {
  font-size: 18px;
  padding-bottom: 16px;
}

.custom-body {
  color: #68737D !important;
  font-size: 16px !important;
  padding-bottom: 32px;
}

.email-subscription-popup {
  font-weight: 600;
  .modal-content {
    border-radius: 12px;
  }
}

.close-button{
  border-radius: 12px;
  height: 40px;
  width: 94px;
  font-weight: 600 !important;
  font-size: 16px !important;
  border-width:2px !important;
}