@use 'sass:map';

.form {
  .form-group {
    label {
      @include p4;
      color: map.get($theme, default-800)
    }

    input {
      height: 40px;
      /* Gray/000 */
      // background: #FFFFFF;
      /* Gray / 300 */

      // border: 1px solid #D8DCDE;
      box-sizing: border-box;
      border-radius: 8px;
    }
  }

  .form-group.required {
    label:after {
      content: " *";
      color: rgb(206, 17, 38);
    }
  }
}