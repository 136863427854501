@use 'sass:map';

.color-guide-training-modal {
  .modal-dialog {
    max-width: 620px;
  }
  
  .modal-content {
    padding: 0 12px;
    border-radius: 10px;
  }
  
  .modal-header {
    border: 0;
    // padding-bottom: 0;
  }

  .modal-footer {
    border: 0;
  }

  .modal-body {
    padding: 0 12px;

    .title-section {
      // margin-bottom: 24px;
      border-bottom: 1px solid map-get($theme, default-200);
      margin-bottom: 24px;
    }

    .color-guide-image {
      max-width: 120px;
      max-height: 90px;
    }
  }
}