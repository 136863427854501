@use 'sass:map';

.login-modal {
  .modal-dialog {
    // max-width: 444px;
  }
  
  .modal-content {
    padding: 0 12px;
    border-radius: 10px;
  }
  
  .modal-header {
    border: 0;
    // padding-bottom: 0;
  }

  .modal-footer {
    border: 0;
    background: map.get($theme, default-100);
    // display: flex;
    // text-align: center;
    justify-content: center;

    a {
      color: map.get($theme, default-900);
      text-decoration: underline;
    }

  }

  .modal-body {
    padding: 0 12px;

    .title-section {
      margin-bottom: 24px;
    }

    a {
      color: map.get($theme, default-900);
      text-decoration: underline;
    }
  }
}