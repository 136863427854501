@use 'sass:map';

.custom-alert {
  background: map.get($theme, alert-bg);
  border-radius: 12px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 25px;
  margin: 24px auto;

  p {
    color: map.get($theme, default-700);
    // max-width: 189px;
    margin-bottom: 0;
    a {
      // @include p2;
      color: map.get($theme, primary);
      text-decoration: underline;
      // display: block;
    }
  }
}