@use 'sass:map';

.downloads-section {
  h6 {
    margin: 0;
    padding-top: 14px;
    padding-bottom: 10px;
    border-bottom: 1px solid map-get($theme, default-200);
  }
  .header {
    height: 40px;
    background: #FAFAFA;
    /* Borders / Top & Bottom */
    box-shadow: inset 0px -1px 0px #E9EBED, inset 0px 1px 0px #E9EBED;
    span {
      @include buttons-2;
      color: map.get($theme, default-900)
    }
  }
  .list-group {
  // max-width: 684px;
    .list-group-item {
      padding-top: 4px;
      padding-bottom: 4px;
      margin: 0;
      p.p3 {
        color: map.get($theme, primary)
      }
      div small {
        color: map.get($theme, typescale-light);
      }
    }
    .list-group-item:hover {
      background: #FAFAFA;
    }
  }

}


