@use 'sass:map';

.page-header {
  @include spacing-horizontal;
  padding-top: 24px;
  padding-bottom: 32px;
  background-color: map.get($theme, page-header-bg);
  text-align: left;
  p {
    color: map.get($theme, pageheader-subheading-color);
  }
}

.page-header h3, .page-header-lg h3 {
  color: map.get($theme, page-header-color);
}
.text-center-align{
  text-align: center !important;
}
.page-header-lg {
  @include spacing-horizontal;
  padding-top: 48px;
  padding-bottom: 48px;
  background-color: map.get($theme, page-header-bg);
  text-align: center;
  color: map.get($theme, pageheader-subheading-color) !important;

  p {
    color: map.get($theme, pageheader-subheading-color);
    a {
      color: map.get($theme, pageheader-subheading-color) !important;
      text-decoration: underline;
      font-weight: 700;
    }
  }
 
}

