@use 'sass:map';

.module-list {
  // padding-top: 59px;
  .list-group {
    // max-width: 684px;
    .list-group-item {
      padding: 14px 0px;
      .list-item {
        @include buttons-1;
        color: map.get($theme, typescale)
      }
    }
  }
  .category {
    padding: 11px 0px;
    margin-bottom: 0;
    color: map.get($theme, default-500)
  }
  .module-name{
    padding-right: 20px;
  }
}


