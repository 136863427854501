@use 'sass:map';
.video-container{
    .video-thumbnail-container{
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .thumbnail {
        width: 100%;
        border-radius: 8px;
        }
    }
    .lightbox {
        backdrop-filter: blur(20px);
        z-index: 1000;
        overflow: scroll;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;

        .lightbox-container {
            position: relative;
            max-width: 960px;
            margin: 7% auto;
            display: block;    
            padding: 0 3%;
            height: auto;
            z-index: 10;
            
            // Increasing top margin for mobile
            @media screen and (max-width: 768px) { margin-top: 10%; }
            @media screen and (max-width: 414px) { margin-top: 13%; }
        }

        .lightbox-content {
                box-shadow: 0 1px 6px fade(black, 70%);
                width:960px; 
            @media screen and (max-width: 768px) {
                width:700px; 
            }
            @media screen and (max-width: 414px) { 
                width:300px; 
            }
            
        }

        .lightbox-close {
            text-transform: uppercase;    
            background: transparent;
            font-weight: 600;      
            font-size: 12px;
            display: block;
            border: none;
            
        }
    }
    iframe {
        width:960px; 
        height:540px;
         @media screen and (max-width: 768px) {
            width:700px; 
            height:400px;
        }
        @media screen and (max-width: 414px) { 
            width:300px; 
            height:200px; }
    }
}

