@use 'sass:map';

.user-thumb {
  // width: 32px;
  // height: 32px;
  border-radius: 50px;

  img {
    border-radius: 50px;
  }
}

