@use 'sass:map';

.post-comment-section {
  border-top: 1px solid map.get($theme, default-200);

  textarea {
    width: 616px;
    height: 80px;

    /* Gray / 100 */
    background: map.get($theme, default-100);
    /* Gray / 300 */

    border: 1px solid #D8DCDE;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px;
    margin-bottom: 16px;

    @media screen and (min-width: $small) {
      width: 100%;
    }

    @media screen and (min-width: $medium) {
      width: 616px;
    }
  }

  textarea::placeholder {
    @include p3;
    color: map.get($theme, default-500);
  }

  .form {
    width: 100%;
  }

  // button {
  //   max-width: 132px;
  // }
}