@use 'sass:map';

.button-detailed-primary {
  padding: 14px 24px 14px 24px;
  box-shadow: 0px 4px 16px rgba(1, 69, 178, 0.2), inset 0px -2px 0px #0145B2;
  border-radius: 8px;
  .text {
    @include buttons-1;
    margin-bottom: 0px;
    text-align: left;
  }
  
  span {
    color: $white;
    text-align: left;
  }

  .icon {
    background: map.get($theme, button-primary-bg-focus);
    width: 32px;
    height: 32px;
    padding: 9.3px;
    border-radius: 20px;
  }
}


.button-detailed-outline {
  padding: 14px 24px 14px 24px;
  box-shadow: 0px 2px 4px rgba(1, 88, 226, 0.1);
  border-radius: 8px;
  .text {
    @include buttons-1;
    margin-bottom: 0px;
    text-align: left;
  }

  span {
    color: map.get($theme, alert-file-color);
    text-align: left;
  }

  .icon {
    background: map.get($theme, alert-file-bg);
    width: 32px;
    height: 32px;
    padding: 9.3px;
    border-radius: 20px;
  }
}


.button-detailed-tertiary {
  padding: 14px 0px 14px 0px;
  box-shadow: inset 0px -1px 0px #E9EBED;
  border-radius: 0;
  .text {
    @include buttons-2;
    margin-bottom: 0px;
    text-align: left;
  }

  span {
    color: map.get($theme, alert-file-color);
    text-align: left;
  }

  .icon {
    // background: map.get($theme, alert-file-bg);
    width: 32px;
    height: 32px;
    // padding: 9.3px;
    border-radius: 20px;
    img {
      width: 16px;
      height: 16px;
    }
  }

  
}

.button-detailed-tertiary.button-tertiary:hover {
  background-color: none !important;
}


