.getting-started-footer-div {
    background-color: #F1FEFE;
    padding-top: 72px;
    padding-bottom: 72px;
    display: flex;
    margin-top: 48px;
    .alert{
        margin: auto;
        align-items: center;
    }

    .custom-alert {
        a {
            font-weight: 600;
        }
    }
}