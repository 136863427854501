@use 'sass:map';


.community-post {
  margin-bottom: 32px;
  span.author {
    @include buttons-1;
  }
  span.timestamp-info {
    // @include buttons-1;
    color: map.get($theme, default-500);
    margin-left: 8px;
  }
  .post-actions {
    @include buttons-2;
    span { 
      margin-right: 8px;
    }
  }
}
