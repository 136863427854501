@use 'sass:map';

.search-empty-alert {
  margin: 180px auto;
  img {
    margin-bottom: 26px;
  }
  p {
    margin-bottom: 0;
    a {
      color: map.get($theme, default-700);
      text-decoration: underline;
    }
  }
  
}