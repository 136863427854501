@use 'sass:map';

.filter-nav {
  .module-nav {
    a {
      padding-top: 4px !important;
      height: 32px;
    }

    a.active {
      background-color: #E6EFFF;
      border-radius: 12px;
    }

    a:hover {
      color: map.get($theme, primary-500) !important;
    }
  }

  .nav {

    // a {
    a.nav-link {
      @include p3;
      padding-top: 0;
      padding-bottom: 8px;
      color: map.get($theme, typescale-light);
    }

    a.active {
      color: map.get($theme, primary);
    }

    // }
  }

  .title {
    color: map.get($theme, typescale-light)
  }
}