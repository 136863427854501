@use 'sass:map';

.form {
  .form-group {
    label {
      @include p4;
      color: map.get($theme, default-800)
    }

    textarea {
      /* Gray/000 */

      background: #FFFFFF;
      height: 72px;
      /* Gray / 300 */

      border: 1px solid #D8DCDE;
      box-sizing: border-box;
      border-radius: 8px;
    }
  }

  .form-group.required {
    label:after {
      content: " *";
      color: rgb(206, 17, 38);
    }
  }
}

.comment-actions {
  @include buttons-2;
  
  .comment-formats {
    border: none;
    padding: 0;
    
    .ql-link {
      // transform: rotate(-45deg);
    }
  }
}


.ql-container {
  width: 100%;
  min-height: 80px;

  background: #FFFFFF;
  label {
    @include p4;
    color: map.get($theme, default-800)
  }
  @include p4;
  color: map.get($theme, default-800);
  font-size: 1rem;

  border: 1px solid #D8DCDE;
  border-radius: 0 0 8px 8px;

  // resizing
  resize: vertical;
  overflow-y: scroll;

  @media screen and (min-width: $small) {
    width: 100%;
  }

  @media screen and (min-width: $medium) {
    width: 100%;
  }

  .ql-tooltip {
    left: 25px !important;
  }
}

.ql-toolbar {
  border-radius: 8px 8px 0 0; 
}

.ql-toolbar.ql-snow {
  border: none;
}

.ql-editor {
  // @include p4;
  font-size: 1rem;
  font-weight: 400;
  font-style: normal !important;
}

.ql-toolbar button.octicon, .ql-toolbar button.fa {
  border: none;
  background: none;
  width: 1.5em;
  color: #444;
}

.ql-toolbar button.ql-active {
  color: #06c;
}

.ql-editor.ql-blank::before {
  font-style: normal !important;
}