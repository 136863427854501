@use 'sass:map';

// .home-news-section {
//   width: 100%;
//   padding-top: 56px;
//   // padding-bottom: 64px;
//   h5 {
//     margin-bottom: 44px;
//     color: map.get($theme, primary-800)
//   }
//   .home-news {
//   }
  
//   .news-item {
//     // max-width: 276px;
//     img {
//       width: 100%;
//       // height: 162px;
//       margin-bottom: 24px;
//       display: block;
//       justify-content: center;
//       align-items: center;
//     }
//     span {
//       display: block;
//       // font-family: Gordita;
//       font-style: normal;
//       font-weight: bold;
//       font-size: 12px;
//       line-height: 12px;
//       /* identical to box height, or 100% */
//       letter-spacing: 0.1em;
//       text-transform: uppercase;

//       /* Gray / 500 */
//       color: map.get($theme, default-500);
//       margin: 8px 0px;
//     }
//     a.news-title {
//       // max-width: 276px;
//       // font-family: Gordita;
//       font-style: normal;
//       font-weight: bold;
//       font-size: 16px;
//       line-height: 25px;
//       /* or 156% */


//       /* Dark blue */

//       color: #002993;
//       /* Inside Auto Layout */
//       margin: 8px 0px;
//     }
//     p {
//       max-height: 100px;
//       overflow: hidden;
//       // font-family: Gordita;
//       font-style: normal;
//       font-weight: normal;
//       font-size: 14px;
//       line-height: 20px;
//     }
//   }
  
// }

.carousel-control-prev-icon {
  background-image: url("../../assets/icons/carousel-left.svg");
  background-repeat: no-repeat;
  background-size: 40px 40px;
  width: 40px;
  height: 40px;
}

.carousel-control-next-icon {
  background-image: url("../../assets/icons/carousel-right.svg");
  background-repeat: no-repeat;
  background-size: 40px 40px;
  width: 40px;
  height: 40px;
}
