@use 'sass:map';

.auth-page-brand {
  @media screen and (min-width: $small) {
    position: absolute;
    top: 35px;
    left: 10%;
    background-image: url('../../assets/images/Brand.svg');
    background-repeat: no-repeat;
    background-position: center;
    height: 40px;
    width: 40px;
  }

  @media screen and (min-width: $medium) {
    position: absolute;
    top: 35px;
    left: 73px;
    background-image: url('../../assets/images/Brand.svg');
    background-repeat: no-repeat;
    background-position: center;
    height: 40px;
    width: 40px;
  }
  
}
