@use 'sass:map';

.bookmark-list {
  // padding-top: 59px;
  .list-group {
    
    // max-width: 684px;
    .list-group-item {
      padding: 14px 0px;
      // .list-item {
      //   @include buttons-1;
      //   color: map.get($theme, typescale)
      // }
      p {
        margin-bottom: 0;
        padding-bottom: 0;
        color: map.get($theme, default-700)
      }
    }
  }
}


