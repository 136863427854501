@use 'sass:map';

@mixin top-contributors-nav-link {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 12px;


  width: 90px;
  height: 24px;

  background: #FFFFFF;
  color: map.get($theme, primary-400);
  border-radius: 16px;
}
.top-contributors {
  a:hover {
    text-decoration: none;
  }
  .nav-pills {
    .nav-item {
      .nav-link {
        @include top-contributors-nav-link;
        @include buttons-2
      }
      .nav-link.active {
        background: rgba(239, 188, 245, 0.5);
        color: map.get($theme, primary-500);
      }
    }
  }
  div.p4 {
    color: map.get($theme, default-800)
  }
  span.s1 {
    color: map.get($theme, default-500)
  }
}