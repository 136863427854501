@use 'sass:map';

.login-prompt{
    text-align: center;
    background-color: #0059E2;
    width: 100%;
    border-radius: 6px;
    p{
        color: white;
        padding: 10px;
    }
    a{
        color: white;
        text-decoration: underline;
    }
}