@use 'sass:map';
.session-file-list {
  .title {
    font-weight: 600;
  }

  div.bookmark-btn {
    @media screen and (min-width: $small) {
      visibility: visible;
    }

    @media screen and (min-width: $medium) {
      visibility: hidden;
    }
    
  }
}

.session-file-list:hover div.bookmark-btn {
  visibility: visible;
}

.list-group {
  // max-width: 684px;
  .list-group-item {
    padding-top: 0;
    margin-bottom: 8px;
    p.p3 {
      color: map.get($theme, primary)
    }
    p.p4 {
      padding-right: 33px;
    }
    div small {
      color: map.get($theme, typescale-light);
    }
    // div .bookmark-btn {
    //   position: absolute;
    // }
    a:hover {
      color: map.get($theme, primary)
    }
  }
}

