@use 'sass:map';

.page-content {
  @include spacing-horizontal;
  padding-top: 40px;
  margin-bottom: 40px;
  // max-width: 684px;
}

.bg-grey {
  // background: map.get($theme, bg-light);
  box-shadow: inset 0px 1px 0px #E9EBED;
  // border-bottom: 1px solid map-get($theme, default-200);
}