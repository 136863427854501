@use 'sass:map';

.discussion-filter-nav {
  .discussion-nav {
     a {
      padding-top: 4px !important;
       height: 32px;
    }
    a.active {
        background-color: rgba(239, 188, 245, 0.1) !important;
          border-radius: 12px;
         
    }
  }
  .nav {
    // a {
      // a.nav-link  {
      //   @include p3;
      //   padding-left: 0;
      //   padding-top: 0;
      //   padding-bottom: 8px;
      //   color: map.get($theme, typescale-light);
      // }

      a.active {
        // background: rgba(239, 188, 245, 0.1);
        // border-radius: 12px;
        h6 {
          color: map.get($theme, primary) !important;
        }
        
      }

    // }
  }
}