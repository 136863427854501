@use 'sass:map';

.slides-banner{
  background:rgba(255, 211, 163, 0.2);
  border-radius: 12px;
  padding: 11px;

  p {
    max-width: 189px;
    margin: 13px;
    a {
      display: block;
    }
  }
}