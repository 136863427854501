@use 'sass:map';
// .session-file-list {
//   .title {
//     font-weight: 600;
//   }

//   button.bookmark-btn {
//     visibility: hidden;
//   }
// }

// .session-file-list:hover button.bookmark-btn {
//   visibility: visible;
// }

// .list-group {
//   // max-width: 684px;
//   .list-group-item {
//     padding-top: 0;
//     margin-bottom: 8px;
//     p.p3 {
//       color: map.get($theme, primary)
//     }
//     div small {
//       color: map.get($theme, typescale-light);
//     }
//     // div .bookmark-btn {
//     //   position: absolute;
//     // }
//     a:hover {
//       color: map.get($theme, primary)
//     }
//   }
// }

.additional-tools-link:hover {
  cursor: pointer;
  text-decoration: underline;
}
