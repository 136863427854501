@use 'sass:map';

.file-preview-modal {
  z-index: 9999999;
  iframe {
    background-color: map-get($theme, primary-100) !important;
    background: url('../../assets/icons/spinner.gif');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 32px 32px;;
  }
  .modal-dialog {
    // max-width: 540px;
    width: 90%;
    margin: auto;
  }
  
  .modal-content {
    padding: 0 0px;
    border-radius: 10px;

    .embed-section {
      height: 500px;
      background-color: map-get($theme, primary-100) !important;
      background: url('../../assets/icons/spinner.gif');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 32px 32px;;
    }
  }
  
  .modal-header {
    padding-top: 12px;
    // max-height: 64px;
    border: 0;
    // padding-bottom: 0;
    box-shadow: 0px 2px 8px rgba(93, 128, 156, 0.15), inset 0px -1px 0px #E9EBED;
    .modal-title {
      max-width: 540px;
      line-height: inherit;
      font-size: inherit;
      h5 {
        
        color: #002993;
      }
    }

    div {
      img.close-btn {
        cursor: pointer;
      }
    }


  }

  .modal-footer {
    border: 0;
  }

  .modal-body {
    padding: 0 12px;
    overflow-y: scroll;
    // max-width: 540px;
    .embed-section {
      // justify-content: center !important;
    }

    .title-section {
      margin-bottom: 24px;
    }
  }
}