@use 'sass:map';

// body {
//   background: map.get($theme, default-100);
// }

.auth-page {
  padding-top: 64px;
  .content {
    max-width: 480px; 
    h4 {
      @media screen and (min-width: $small) {
        margin-top: 20px;
        margin-bottom: 30px;
      }
      @media screen and (min-width: $medium) {
        margin-bottom: 40px;
      }
    }

    .section-middle {
      
      @media screen and (min-width: $small) {
        box-shadow: none;
      }

      @media screen and (min-width: $medium) {
        box-shadow: 0px 2px 4px rgba(1, 88, 226, 0.1);
      }

      border-radius: 10px;
      background: $white;
      padding: 40px 40px 16px 40px;

      img { 
        margin-bottom: 32px;
      }

      .form {
        .form-group {
          margin-bottom: 24px;
        }
      }

      .frame {
        box-shadow: inset 0px 1px 0px #E9EBED;
        padding-top: 20px;
        padding-bottom: 20px;

        p {
          margin-bottom: 0;
        }
      }
    }
  }
}