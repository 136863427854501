@use 'sass:map';

.file-alert {
  padding: 12px 24px 12px;
  width: 100%;
  background-color: map-get($theme, alert-file-bg);
  border-radius: 12px;
  margin-top: 20px;
  margin-bottom: 40px;
  // max-width: 684px;

  span {
    @include buttons-1;
    color: map.get($theme, alert-file-title-color);
    margin-bottom: 0;
    display: block;
  }
  small {
    color: map.get($theme, alert-file-color);
  }
}

.terms, .copyright {
  .s1, .s2 {
    // font-size: 11px !important;
  }
}

.footer-end {
  .partner-logos {
    img {
      margin: 0 !important;
    }
  }
}

@media screen and (min-width: $medium) {
  .terms, .copyright {
    .s1, .s2 {
      font-size: 11px !important;
    }
  }
}

@media screen and (max-width: $medium) {
  .page-footer {
    padding-top: 24px !important;
    padding-bottom: 24px !important;
    font-size: 13px !important;
  }
  .copyright {
    padding-bottom: 24px !important;
  }
}
