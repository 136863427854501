@use 'sass:map';

.container-xl {
  @media screen and (min-width: $small) {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%281, 88, 226, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

// Home
.about-top-section {
  max-width: 990px;
}

.about-highlights  {
  padding: 48px 0 48px 0;
  div {
    h3 {
      color: map.get($theme, highlight-800);
    }

    img {
      margin-bottom: 24px;
    }

    p {
      color: map.get($theme, highlight-700);
    }
  }
}

.page-content.about-page {
  .row {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}

.contribution {
  .label {
    h4 {
      max-width: 236px;
      @media screen and (min-width: $small) {
        max-width: 100%;
      }
    }


  }

  .values {
    p {
      margin-bottom: 0;
    }
    a {
      color: map.get($theme, typescale-light);
      text-decoration: none;
    }
    a:hover {
      text-decoration: underline;
      text-decoration-color: map.get($theme, typescale-light);
    }
  }

}

.page-footer {
  padding-top: 40px;
  padding-bottom: 40px;
  background: map.get($theme, default-100);

  .footer-nav {
    border-bottom: 1px solid map-get($theme, default-200);
  }

  .nav-section {
    margin-bottom: 24px;
    p.c1 {
      display: block;
      color: map.get($theme, default-400);
    }
    a.p4 {
      display: block;
      margin-bottom: 4px;
      color: map.get($theme, default-600);
    }
  }

  .terms {

    padding-top: 32px;
    padding-bottom: 32px;
    p.s1 {
      // max-width: 1176px;
      color: map.get($theme, default-500);
      margin-bottom: 0;
      font-size: 13px;
      line-height: 20px;
      a {
        color: map.get($theme, default-500);
      }
    }

  }

  .footer-end {
    border-top: 1px solid map-get($theme, default-200);
    padding-top: 32px;
    // padding-bottom: 32px;
    .copyright {
      // max-width: 676px;
    }

    p {
      color: map.get($theme, default-500);
    }

    .partner-logos {
      img {
        // margin-right: 48px;
      }

      @media screen and (min-width: $small) {
        img {
          height: 24px;
          width: 80px;
          margin-right: 32px;
          margin-top: 24px;
          margin-bottom: 24px;
        }
        #unfpa-logo {
          width: unset;
        }
      }

      @media screen and (min-width: $medium) {
        img {
          height: 40px;
          width: 134px;
          margin-right: 0px;
          margin-left: 32px;
        }
        #unfpa-logo {
          width: unset;
        }
      }
    }

  }
}


// User profile

.profile-header {
  padding-top: 24px;
  padding-bottom: 24px;
}
.public-profile-header {
  box-shadow: 0px 8px 12px 4px rgba(104, 115, 125, 0.05), inset 0px -1px 0px #E9EBED;
  padding-top: 24px;
  padding-bottom: 24px;
}
.profile-settings-page {
  // max-width: 800px;

  .profile-settings-section {
    padding-top: 24px;
    box-shadow: inset 0px 1px 0px #E9EBED;
    h5 {
      margin-bottom: 4px;
    }
  }
}


.carousel {
  margin-top: 48px;
  margin-bottom: 48px;
  background: map.get($theme, default-100);
  padding: 30px;
}

a {
  color: map.get($theme, primary-600);
}

a.know-more {
  @include p3;
  color: map.get($theme, default-800)
}

button.square-btn {
  border-radius: 4px !important;
}

.other-language-materials {
  div {
    p.p3 {
      max-width: 684px;
    }
  }

}

// home
.home-partner-logos {
  border-top: 1px solid #FAFAFA;
}

.news-and-updates-link {
  a:hover{
    text-decoration: none;
    .newsAndUpdatesTitle {
      color: #0145B2;
    }
  }
}

.community-guidelines-container {
  max-width: 786px;
}

.filetype{
  margin-left: 1rem;
}
.filesize{
  margin-left: 2rem;
}

.profile-photo-section {
  .profile-photo-help {
    word-break: normal;
  }
}

.tf-v1-popover-button {
  background-color: #0145B2;
}

.materials-video-block-small {
    .card-header {
        padding-left: 0;
    }
    .video-container {
        padding: 0;
        min-height: 105px;
    }
    .card-body {
        padding: 0.95rem;
        padding-top: 0;
    }
}

.source-link {
  color: black;
  text-decoration: underline !important;
  font-size: 14px;
  font-family: 'SourceSansProRegular', sans-serif;
  gap: 4px;
}
.source-link:hover {
  color: #0158E2;
  .source-link-icon {
    filter: invert(19%) sepia(64%) saturate(5000%) hue-rotate(212deg) brightness(94%) contrast(101%);
  }
}
.source-link-icon {
  height: 12px; 
  padding-top: 2px;
}
