@use 'sass:map';

.search-bar {
  input {
    @include p3;
    padding: 10px 20px 10px 0;
    height: 44px;
    /* Gray/000 */
    background: $white;
    /* Gray / 300 */
    border: 1px solid map-get($theme, input-border-color );
    border-left: 0;
    border-right: 0;
    box-sizing: border-box;
    border-radius: 12px;
    // color: black;
  }
  
  input[type=search]::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }

  input:focus {
    border-color: map-get($theme, input-border-color);
  }

  input::placeholder {
    @include p3;
  }

  .input-group-prepend {
    // border-radius: 12px;

    .input-group-text {
      background: $white;
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
      padding-left: 20px;
      padding-right: 16px;
      img {
        width: 16px;
        height: 16.1px;
      }
    }
    
  }

  .input-group-append {
    // border-radius: 12px;

    .input-group-text {
      background: $white;
      border-top-right-radius: 12px;
      border-bottom-right-radius: 12px;
      padding-left: 20px;
      padding-right: 16px;
      cursor: pointer;
      border-left: 0px;
      img {
        width: 12px;
        height: 12px;
      }
      .opacity-0 {
        opacity: 0;
      }
    }
    
  }

}

.ms-n5 {
  margin-left: 40px;
}