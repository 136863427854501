@use 'sass:map';

.site-search {
  background: #F0F6FF;
  padding-top: 16px;
  padding-bottom: 16px;

  div.container-xl {
    padding-left: 50px;
    padding-right: 50px;
    div.holder {
      position: relative;
      width: 100% !important;

      input {
        @include p3;
        background: #FFFFFF;
        /* Blue/300 */
  
        border: 1px solid #B3D0FF;
        border-radius: 0;
        box-sizing: border-box;
        box-shadow: 0px 2px 4px rgba(135, 146, 157, 0.1);
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
      }
  
      input::placeholder {
        @include p3;
      }

      .dropdown {
        position: absolute;
        // border: 1px solid red;
        display: none;
        width: 100%;
        z-index: 99999;
      }

      input:focus + .dropdown {
        display: block;
      }

      .dropdown:hover {
        display: block;
      }

    }
    
  }

  
}

body {
  // background-color: rgba(0,0,0,0.5);
}

.search-results-container {
  // position: fixed;
  background: $white;
  top: 16%;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  // overflow: scroll;
}

.search-results {
  padding-top: 44px;
  width: 75%;
}
