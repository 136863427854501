@use 'sass:map';

.profile-card  {
  .user-thumb {
    width: 72px;
    height: 72px;
  }

  p, h4 {
    margin-bottom: 0;
  }

  .p5 {
    color: map.get($theme, highlight-600)
  }
  .linkedin {
    color: #03080C;
  }
  
}