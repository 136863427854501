@use 'sass:map';

.faq-accordion {
  h5 {
    color: map.get($theme, typescale);
  }
  margin-bottom: 48px;
}


// .accordian {
//   width: 100%;
// }

// .accordion-button {

//   span {
//     @include buttons-1;
//   }

//   p.subtext {
//     @include button-small;
//     padding-left: 0;
//     margin-bottom: 0;
//     color: map.get($theme, primary)
//   }
// }

// .card {
//   border-left: 0;
//   border-right: 0;
//   border-bottom: 0;
//   box-shadow: inset 0px 1px 0px #E9EBED;
//   .card-body {
//     padding-top: 0;
//   }
// }

// .card-header {
//   border-bottom: 0;
// }