// @import '~antd/dist/antd.css';
@import "./styles/main.scss";

html, body {
  min-height: 100%;
}

#root {
  height: 100%;
}

.App {
  // text-align: center;
  height: 100vh;
  overflow: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.training-index-left {
  max-width: 378px;

  .bottom-section {
    max-width: 276px;
  }
}

.training-index-right {
  padding-left: 33px;
}

.training-overview-left  {
  // @media screen and (min-width: 960px) {
  //   max-width: 684px;
  // }
}

// Community
.community-search {
  max-width: 582px;
}


.sticky-div {
  z-index: 999;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
}

div[role="dialog"][aria-modal="true"]:nth-last-child(1) {
  z-index: 1125;
}
.modal-backdrop.show:nth-last-child(2){
  z-index: 1100;
}
div[role="dialog"][aria-modal="true"]:nth-last-child(3) {
  z-index: 1075;
}
.modal-backdrop.show:nth-last-child(4){
  z-index: 1050;
}
div[role="dialog"][aria-modal="true"]:nth-last-child(5) {
  z-index: 1025;
}
.modal-backdrop.show:nth-last-child(6){
  z-index: 1000;
}

.tf-popup-button {
  // background-color: rgb(1, 88, 226) !important;
  background-color: #FF6B00 !important;
  position: absolute;
  top: 50%;
  right: -36px;
  border-radius: 8px 8px 0 0;
  border: none;
  padding: 10px;
  color: white;
  font-weight: 400;
  transform: rotate(270deg);
  z-index: 999;
  // transition to float from bottom
  // transition: all 0.5s ease-in-out;
  // animation: tf-popup-animation 0.5s ease-in-out;
}

// @keyframes tf-popup-animation {
//   // popup from bottom
//   0% {
//     bottom: -100px;
//   }
//   100% {
//     bottom: 20px;
//   }
// }

