@use 'sass:map';

.suggestions {
  border: 1px solid map.get($theme, primary-300);
  div.scroll-results {
    max-height: 350px; 
    overflow: scroll;
  }
  
  div.section {
    .title {
      background: map.get($theme, default-100);
      height: 32px;
      padding-left: 20px;
      padding-top: 10px;
      padding-bottom: 9px !important;
      span {
        
      }
    }

    .list-group {
      .list-group-item {
        background: $white !important;
        min-height: 48px;
        margin-bottom: 0px;
        border-left: 0;
        border-right: 0;
        color: map.get($theme, default-900);
        padding-left: 20px;
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
    
  }
  div.footer {
    background: $white;
    height: 48px;
    padding-top: 12px;
    padding-bottom: 16px;
    padding-left: 20px;
  }
}