@use 'sass:map';

.partners-container {
  padding-left: 0px;
  gap: 3rem;
}

@media screen and (max-width: 868px) {
  .partners-container {
    display: flex;
    flex-wrap: wrap;
  }
}